import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'react-router-redux'

import HomeView from '../views/HomeView'
import UsersView from '../views/UsersView'
import UserAddForm from '../forms/UserAddForm'
import UserEditForm from '../forms/UserEditForm'
import UserDetailsView from '../views/UserDetailsView'
import NotFoundView from '../views/NotFoundView'

import AccessibilityView from '../views/AccessibilityView'
import PaymentView from '../views/PaymentView'
import PhoneView from '../views/PhoneView'
import TicketView from '../views/TicketView'
import PaymentError from '../views/PaymentError'
import LockerSelectorView from '../views/LockerSelectorView'
import ZoneSelectorView from '../views/ZoneSelectorView'
import OTOSelectorView from '../views/OTOSelectorView'
import OTOAccessView from '../views/OTOAccessView'
import OTOOpenView from '../views/OTOOpenView'

class App extends React.Component {
       render() {
              return (
                     <div>
                            <div className="container pt-3">
                                   <Switch>
                                          <Route path="/" exact
                                                 component={HomeView} />
                                          <Route path="/users" exact
                                                 component={UsersView} />
                                          <Route path="/users/add" exact
                                                 component={UserAddForm} />
                                          <Route path="/users/:number/edit"
                                                 component={UserEditForm} />
                                          <Route path="/users/:number"
                                                 component={UserDetailsView} />
                                          <Route path="/accessibilityView"
                                                 component={AccessibilityView} />
                                          <Route path="/paymentView"
                                                 component={PaymentView} />
                                          <Route path="/phoneView"
                                                 component={PhoneView} />
                                          <Route path="/ticketView"
                                                 component={TicketView} />
                                          <Route path="/lockerSelectorView"
                                                 component={LockerSelectorView} />
                                          <Route path="/zoneSelectorView"
                                                 component={ZoneSelectorView} />
                                          <Route path="/otoSelectorView"
                                                 component={OTOSelectorView} />
                                          <Route path="/otoAccessView"
                                                 component={OTOAccessView} />
                                          <Route path="/otoOpenView"
                                                 component={OTOOpenView} />
                                          <Route path="/paymentError"
                                                 component={PaymentError} />
                                          <Route path="*"
                                                 component={NotFoundView} />
                                   </Switch>
                            </div>
                     </div>
              )
       }
}

export default App
