import React from 'react'
import { Provider } from 'react-redux'
import { Switch, Route } from 'react-router'
import { ConnectedRouter } from 'react-router-redux'

import App from './App'

import { requireAuthentication } from '../components/AuthenticatedComponent'
import LoginAdminView from '../views/LoginAdminView'
import LoginCustomerView from '../views/LoginCustomerView'
import HomeView from '../views/HomeView'
import SoldOutView from '../views/SoldOutView'
import SoldOutTypeView from '../views/SoldOutTypeView'
import AccessibilityView from '../views/AccessibilityView'
import PaymentView from '../views/PaymentView'
import PhoneView from '../views/PhoneView'
import TicketView from '../views/TicketView'
import PaymentError from '../views/PaymentError'
import LockerSelectorView from '../views/LockerSelectorView'
import ZoneSelectorView from '../views/ZoneSelectorView'
import OTOSelectorView from '../views/OTOSelectorView'
import OTOAccessView from '../views/OTOAccessView'
import OTOOpenView from '../views/OTOOpenView'

import 'bootstrap/dist/css/bootstrap.css';
import '../style.css'
import i18n from "./../i18n";
import { withNamespaces } from 'react-i18next';

class Root extends React.Component {
  render() {
    return (
      <Provider store={this.props.store} i18n={i18n}>
        <ConnectedRouter history={this.props.history}>
            <Switch>
              <Route exact path="/login/admin" component={LoginAdminView} />
              <Route exact path="/login" component={LoginCustomerView} />
              <Route exact path="/qrsale/:id" component={HomeView} />
              <Route exact path="/soldOut" component={SoldOutView} />
              <Route exact path="/zoneSelectorView/:id" component={ZoneSelectorView} />
              <Route exact path="/lockerSelectorView" component={LockerSelectorView} />
              <Route exact path="/accessibilityView" component={AccessibilityView} />
              <Route exact path="/soldOutType" component={SoldOutTypeView} />
              <Route exact path="/paymentView" component={PaymentView} />
              <Route exact path="/phoneView" component={PhoneView} />
              <Route exact path="/ticketView" component={TicketView} />
              <Route exact path="/paymentError" component={PaymentError} />
              <Route exact path="/otoSelectorView/:id" component={OTOSelectorView} />
              <Route exact path="/otoAccessView/:id" component={OTOAccessView} />
              <Route exact path="/otoOpenView/:id" component={OTOOpenView} />
              <Route path="/" component={requireAuthentication(App)} />
            </Switch>
        </ConnectedRouter>
      </Provider>
    )
  }
}

export default withNamespaces()(Root)
